import React from 'react';
import Avatar from '@mui/material/Avatar';
import Photo from '../static/profile_photo.jpg';
import { styled } from '@mui/system';

const RootDiv = styled('div')({
  flexGrow: 1,
  textAlign: 'center',
});

const StyledH1Span = styled('span')({
  fontSize: '500%',
  padding: '0px 0px 0px 0px',
});

const StyledH2Span = styled('span')({
  fontSize: '150%',
  marginTop: '0px',
});

const StyledAvatar = styled(Avatar)({
  height: '7em',
  width: '7em',
  margin: '0 auto',
});

const Landing = () => {
  const spanProps = {
    align: 'center',
    style: {
      fontSize: '125%',
    },
  };

  return (
    <RootDiv align="center">
      <StyledAvatar alt="Daksh Jain" src={Photo} component={'span'} />
      <StyledH1Span align="center">
        <b>Daksh Jain</b>
      </StyledH1Span>
      <br />
      <StyledH2Span align="center">
        {/* SDE Intern at Mystry <br /> */}
        SWE at SimpliSafe
      </StyledH2Span>
      <br />
      <br />
      {/* <span {...spanProps}>
        A result-oriented recent grad who aspires to become a top software
        engineer.
      </span>
      <br /> */}
      <span {...spanProps}>
        Interested in software engineering, web development, and AI and ML.
      </span>
      <br />
      <span {...spanProps}>
        Passionate about computers, technology, and gaming.
      </span>
    </RootDiv>
  );
};

export default Landing;
