import React from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import ArticleIcon from '@mui/icons-material/Article';
// import PhoneIcon from '@mui/icons-material/Phone';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/system';

const RootDiv = styled('div')(({ theme }) => ({
  '& > *': {
    margin: theme.spacing(0.01),
  },
}));

const StyledButton = styled(Button)({
  textTransform: 'none',
});

const StyledTable = styled(Table)({
  minWidth: 0,
});

const noOfComponents = 4;

const tableCellStyle = {
  borderBottom: 'none',
  width: `calc(100%/${noOfComponents})`,
};

const Contacts = () => {
  return (
    <RootDiv>
      <TableContainer component={Paper}>
        <StyledTable padding="checkbox">
          <TableBody>
            <TableRow>
              <TableCell sx={tableCellStyle} align="center">
                <a
                  href="https://www.linkedin.com/in/jain-daksh/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <StyledButton startIcon={<LinkedInIcon />}>
                    jain-daksh
                  </StyledButton>
                </a>
              </TableCell>
              <TableCell sx={tableCellStyle} align="center">
                <a
                  href="https://github.com/dakshj48"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <StyledButton startIcon={<GitHubIcon />}>
                    dakshj48
                  </StyledButton>
                </a>
              </TableCell>
              <TableCell sx={tableCellStyle} align="center">
                <a
                  href="mailto:dakshjain48@gmail.com"
                  style={{ textDecoration: 'none' }}
                >
                  <StyledButton startIcon={<EmailIcon />}>
                    dakshjain48@gmail.com
                  </StyledButton>
                </a>
              </TableCell>
              <TableCell sx={tableCellStyle} align="center">
                <a
                  href="https://drive.google.com/file/d/14AX92Dqfm0KNX5Ga3mN3QqXebiQ5AxoA/view"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <StyledButton startIcon={<ArticleIcon />}>
                    Resume
                  </StyledButton>
                </a>
              </TableCell>
              {/* <TableCell sx={tableCellStyle} align='center'>
                <a 
                  href='tel:+13123699090'
                  style={{textDecoration: 'none'}}
                >
                  <StyledButton
                    color='default'
                    startIcon={<PhoneIcon />}
                  >
                    (312) 369-9090
                  </StyledButton>
                </a>
              </TableCell> */}
            </TableRow>
          </TableBody>
        </StyledTable>
      </TableContainer>
    </RootDiv>
  );
};

export default Contacts;
