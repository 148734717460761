import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';

const RootDiv = styled('div')({
  flexGrow: 1,
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'flex-start',
}));

const TechnicalSkills = () => {
  return (
    <RootDiv>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <StyledPaper>
            <b>Languages:</b> <i>Proficient:</i> JavaScript, TypeScript{' '}
            <i>Familiar:</i> Python, Java, SQL, C
          </StyledPaper>
        </Grid>
        <Grid item xs={12}>
          <StyledPaper>
            <b>Frameworks/Services:</b> Node.js, NestJS, AWS, Kafka, Docker,
            Kubernetes, Firebase, PyTorch
          </StyledPaper>
        </Grid>
        <Grid item xs={12}>
          <StyledPaper>
            <b>Front-end:</b> ReactJS, Material-UI, Semantic-UI, HTML5, CSS3
          </StyledPaper>
        </Grid>
        <Grid item xs={12}>
          <StyledPaper>
            <b>Tools:</b> Kibana, Grafana, Git, Bash, Honeycomb, OpsGenie, Jira
          </StyledPaper>
        </Grid>
        <Grid item xs={12}>
          <StyledPaper>
            <b>Methodologies:</b> Object oriented programming, Unit testing,
            RESTful API, Version control, OTel
          </StyledPaper>
        </Grid>
        {/* <Grid item xs={12}>
          <StyledPaper>
            <b>Soft skills:</b> Communication, Collaboration, Leadership,
            Critical thinking
          </StyledPaper>
        </Grid> */}
      </Grid>
    </RootDiv>
  );
};

export default TechnicalSkills;
