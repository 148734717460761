import React, { useState, useEffect } from 'react';
import Education from './Education';
import Experience from './Experience';
import Projects from './Projects';
import TechnicalSkills from './TechnicalSkills';
import HonorsAndAwards from './HonorsAndAwards';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fab from '@mui/material/Fab';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-scroll';
import { styled } from '@mui/system';

const RootDiv = styled('div')({
  width: '100%',
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
  fontWeight: theme.typography.fontWeightRegular,
}));

const StyledFab = styled(Fab)({
  margin: 0,
  top: 'auto',
  right: 20,
  bottom: 25,
  left: 'auto',
  position: 'fixed',
});

const Resume = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;
  const isMobileLandscaped = height <= 500 && width <= 1100;

  const handleChange = (panel) => (event, newExpanded) => {
    if (expanded.includes(panel)) {
      setExpanded(expanded.filter((item) => item !== panel));
    } else {
      setExpanded(expanded.concat(panel));
    }
  };

  const [expanded, setExpanded] = useState([]);

  const expandAll = () => {
    setExpanded([
      'education',
      'experience',
      'projects',
      'technicalskills',
      'honors',
    ]);
  };

  const accordionSummaryStyle = {
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters.css-eqpfi5-MuiAccordionSummary-content':
      {
        marginLeft: '12px',
      },
  };

  const linkProps = {
    spy: true,
    smooth: 'easeInOutQuad',
    isDynamic: true,
    activeClass: 'active',
  };

  return (
    <RootDiv>
      <br />
      <Accordion
        expanded={expanded.includes('experience')}
        onChange={handleChange('experience')}
      >
        <Link to="experience-header" {...linkProps}>
          <AccordionSummary
            sx={accordionSummaryStyle}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="experience-header"
          >
            <StyledTypography>Experience</StyledTypography>
          </AccordionSummary>
        </Link>
        <AccordionDetails>
          <Typography component={'div'}>
            <Experience />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded.includes('education')}
        onChange={handleChange('education')}
      >
        <Link
          {...linkProps}
          to="education-header"
          offset={isMobile ? 0 : isMobileLandscaped ? 0 : -100}
        >
          <AccordionSummary
            sx={accordionSummaryStyle}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="education-header"
          >
            <StyledTypography>Education</StyledTypography>
          </AccordionSummary>
        </Link>
        <AccordionDetails>
          <Typography component={'div'}>
            <Education />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded.includes('technicalskills')}
        onChange={handleChange('technicalskills')}
      >
        <Link
          to="technicalskills-header"
          {...linkProps}
          offset={isMobile ? -195 : isMobileLandscaped ? 0 : -250}
        >
          <AccordionSummary
            sx={accordionSummaryStyle}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="technicalskills-header"
          >
            <StyledTypography>Technical Skills</StyledTypography>
          </AccordionSummary>
        </Link>
        <AccordionDetails>
          <Typography component={'div'}>
            <TechnicalSkills />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded.includes('projects')}
        onChange={handleChange('projects')}
      >
        <Link to="projects-header" {...linkProps}>
          <AccordionSummary
            sx={accordionSummaryStyle}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="projects-header"
          >
            <StyledTypography>Projects</StyledTypography>
          </AccordionSummary>
        </Link>
        <AccordionDetails>
          <Typography component={'div'}>
            <Projects />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded.includes('honors')}
        onChange={handleChange('honors')}
      >
        <Link
          to="honorsandawards-header"
          {...linkProps}
          offset={isMobile ? -425 : isMobileLandscaped ? -50 : -450}
        >
          <AccordionSummary
            sx={accordionSummaryStyle}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="honorsandawards-header"
          >
            <StyledTypography>Honors and Awards</StyledTypography>
          </AccordionSummary>
        </Link>
        <AccordionDetails>
          <Typography component={'div'}>
            <HonorsAndAwards />
          </Typography>
        </AccordionDetails>
      </Accordion>

      {expanded.length < 5 && (
        <Link to="education-header" {...linkProps}>
          <Tooltip title="Expand All" aria-label="expand">
            <StyledFab color="secondary" onClick={expandAll}>
              <ExpandMoreIcon />
            </StyledFab>
          </Tooltip>
        </Link>
      )}
      {expanded.length === 5 && (
        <Tooltip title="Collapse All" aria-label="collapse">
          <StyledFab color="secondary" onClick={() => setExpanded([])}>
            <ExpandLessIcon />
          </StyledFab>
        </Tooltip>
      )}
    </RootDiv>
  );
};

export default Resume;
