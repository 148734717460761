import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';

const RootDiv = styled('div')({
  flexGrow: 1,
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
}));

const Experience = () => {
  return (
    <RootDiv>
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <StyledPaper>
            <b>SimpliSafe</b>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPaper>Remote</StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPaper>
            <i>Software Engineer</i>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPaper>June 2022 - Present</StyledPaper>
        </Grid>
        <Grid item xs={12} sm={12}>
          <StyledPaper>
            <ul style={{ textAlign: 'left' }}>
              <li>
                Responsible for developing features and improvements to services
                used for providing professional monitoring to customers using{' '}
                <b>agile</b> software development.
              </li>
              <li>
                Developed the back-end to support creating and serving betas for
                customers in the app using cutting-edge technologies like{' '}
                <b>DynamoDB</b> and <b>S3</b>.
              </li>
              <li>
                Incorporated <b>caching</b> in memory and database to reduce no.
                of slow requests by more than <b>6x</b> and reduce P99 by{' '}
                <b>5s</b>, improving UX by having shorter loading times when
                saving info to third party monitoring service.
              </li>
              <li>
                Implemented alarm text de-duplication, reducing the number of
                alarm texts sent to customers by <b>25%</b>.
              </li>
              <li>
                <b>Integrated</b> new API from a third party monitoring service
                into an existing microservice
              </li>
              <li>
                Wrote feature, tech-debt and bug tickets to improve features and
                services.
              </li>
              <li>
                Leveraged Node.js, NestJS, TypeScript, JavaScript, AWS, Kibana,
                Honeycomb, Grafana, Github etc. to build, test, deploy, and
                monitor features and services.
              </li>
            </ul>
          </StyledPaper>
        </Grid>
      </Grid>
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <StyledPaper>
            <b>Mystry</b>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPaper>Remote</StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPaper>
            <i>Software Development Engineering Intern</i>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPaper>June 2021 - Aug. 2021</StyledPaper>
        </Grid>
        <Grid item xs={12} sm={12}>
          <StyledPaper>
            <ul style={{ textAlign: 'left' }}>
              <li>
                Spearheaded the development of the company website to act as a
                shoe subscription and marketplace in a startup environment.
              </li>
              <li>
                Designed and developed core components of the website
                marketplace, product, landing, login, and sign-up pages
                optimized for both mobile and desktop views from the ground up.
              </li>
              <li>
                Designed and developed end-to-end guest checkout for the
                website.
              </li>
              <li>
                Designed the back-end for the product details page and user
                information.
              </li>
              <li>
                Utilized React.js for the front-end and Firebase and AWS
                technologies for the back-end.
              </li>
            </ul>
          </StyledPaper>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <StyledPaper>
            <b>College of Information and Computer Science, UMass</b>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPaper>Amherst, MA</StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPaper>
            <i>Teaching Assistant</i>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPaper>Jan. 2019 - May 2019</StyledPaper>
        </Grid>
        <Grid item xs={12} sm={12}>
          <StyledPaper>
            <ul style={{ textAlign: 'left' }}>
              <li>
                Mentored computer science undergraduates at UMass in a computer
                systems course.
              </li>
              <li>
                Answered students’ questions online and in-lab gatherings and
                graded assignments and exams.
              </li>
            </ul>
          </StyledPaper>
        </Grid>
      </Grid>
    </RootDiv>
  );
};

export default Experience;
