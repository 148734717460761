import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';

const RootDiv = styled('div')({
  flexGrow: 1,
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'flex-start',
}));

const HonorsAndAwards = () => {
  return (
    <RootDiv>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <StyledPaper>
            <b>Baystate Scholarship:</b> Scholarship awarded to undergraduates
            who excelled in computer science and are pursuing master's in
            computer science at UMass.
          </StyledPaper>
        </Grid>
        <Grid item xs={12}>
          <StyledPaper>
            <b>Dean's List:</b> Honor awarded to undergraduates who complete a
            minimum of 12 graded credits while maintaining a high grade point
            average. Received all semesters.
          </StyledPaper>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <StyledPaper>
          <b>Chancellor's Award:</b> Scholarship awarded to admitted freshmen
          whose academic and personal achievements are especially strong.
        </StyledPaper>
      </Grid>
    </RootDiv>
  );
};

export default HonorsAndAwards;
