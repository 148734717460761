import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';

const RootDiv = styled('div')({
  flexGrow: 1,
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'flex-start',
}));

const Projects = () => {
  return (
    <RootDiv>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <StyledPaper>
            <b>Detection and Classification of Traffic Signs</b>
            <div style={{ float: 'right' }}>
              <a
                href="https://drive.google.com/file/d/1TSrPhzaBHNgoeXc8YaU43xupCe98WPBK/view?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <i>Project link</i>
              </a>
            </div>
            <ul>
              <li>
                Worked in a team of 2 to train a CNN to do object detection and
                classification on traffic signs using PyTorch.
              </li>
              <li>
                Dataset consisted of 43 classes of traffic signs and a varying
                number of traffic signs per real-world image.
              </li>
              <li>
                Achieved 73.7% and 74.4% average precision and recall values
                under a limited dataset.
              </li>
            </ul>
          </StyledPaper>
        </Grid>
        <Grid item xs={12}>
          <StyledPaper>
            <b>Distributed Book Marketplace</b>
            {/* <div style={{ float: "right" }}>
              <a
                href="https://github.com/dakshj48/Bloglist"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <i>Project link</i>
              </a>
            </div> */}
            <ul>
              <li>
                Worked in a team of 3 to simulate a marketplace in a distributed
                environment with clients and servers.
              </li>
              <li>
                Addressed concepts like load balancing, leader election,
                consistency, fault recovery, and caching.
              </li>
              <li>
                Leveraged technologies like Docker, AWS, Python, and Bash
                scripts to achieve a distributed system.
              </li>
            </ul>
          </StyledPaper>
        </Grid>
        <Grid item xs={12}>
          <StyledPaper>
            <b>Full-stack web app for listing blogs</b>
            <div style={{ float: 'right' }}>
              <a
                href="https://github.com/dakshj48/Bloglist"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <i>Project link</i>
              </a>
            </div>
            <ul>
              <li>
                Users can add links of blogs to the list, like and comment on
                existing blogs in the list and remove added blogs.
              </li>
              <li>
                Utilized MongoDB, Express, React and Node.js (MERN) for
                development and Heroku for deployment.
              </li>
            </ul>
          </StyledPaper>
        </Grid>
        <Grid item xs={12}>
          <StyledPaper>
            <b>Movie Plot Generator</b>
            <div style={{ float: 'right' }}>
              {/* <a
                href="https://colab.research.google.com/drive/1FHsFYf7P5pOL-URpOl5l_1ZlbflMTyG2?authuser=1"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <i>Project link</i>
              </a> */}
            </div>
            <ul>
              <li>
                Worked in a team of 5 to generate movie plots given context and
                genre using NLP algorithms.
              </li>
              <li>
                Harnessed Pytorch and Google Colab to meet the resources needed
                to learn the data from thousands of movies.
              </li>
            </ul>
          </StyledPaper>
        </Grid>
        <Grid item xs={12}>
          <StyledPaper>
            <b>8-D Music Converter</b>
            <div style={{ float: 'right' }}>
              <a
                href="https://github.com/dakshj48/8D-Audio-Converter"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <i>Project link</i>
              </a>
            </div>
            <ul>
              <li>
                A user-friendly application to convert audio files into 8-D
                audio files locally.
              </li>
              <li>
                Utilized open-source Python APIs for complex audio processing
                and Flask to receive and serve audio files.
              </li>
            </ul>
          </StyledPaper>
        </Grid>
        {/* <Grid item xs={12}>
          <StyledPaper>
            <b>
              N-puzzle Solver
            </b>
            <ul>
              <li>
                Designed an AI for solving N-puzzles using the Manhattan distance heuristic using Java.
              </li>
              <li>
                Used data structures like hashmap to significantly reduce the computation time.
              </li>
            </ul>
          </StyledPaper>
        </Grid> */}
        {/* <Grid item xs={12}>
          <StyledPaper>
            <b>Multi-threaded Chat Server and Client</b>
            <ul>
              <li>
                Established communication between a server and multiple clients
                using threads in C++ and Python
              </li>
              <li>
                Features include hosting multiple rooms to which clients can
                connect and direct messaging between clients
              </li>
            </ul>
          </StyledPaper>
        </Grid> */}
        {/* <Grid item xs={12}>
          <StyledPaper>
            <b>
              Cache Simulator and Bank Simulator
            </b>
            <ul>
              <li>
                Implemented a Least Recently Used (LRU) cache in C.
              </li>
              <li>
                Simulated communication in an ATM system (a bank and several ATMs).
              </li>
            </ul>
          </StyledPaper>
        </Grid> */}
      </Grid>
    </RootDiv>
  );
};

export default Projects;
