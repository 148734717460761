import './App.css';
import React from 'react';
import Landing from './components/Landing';
import Resume from './components/Resume';
// import Footer from './components/Footer';
import Contacts from './components/Contacts';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import background from './static/background.jpg';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const App = () => {
  return (
    <div className="App Site">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <div className="Site-content">
            <div className="App-header">
              <img src={background} className="bg" alt="12" />
              <Contacts />
            </div>
            <div className="main">
              <br />
              <div className="Landing">
                <Landing />
              </div>
              <Resume />
            </div>
          </div>
          {/* <div>
            <Footer />
          </div> */}
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

export default App;
