import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/system';

const RootDiv = styled('div')({
  flexGrow: 1,
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
}));

const Education = () => {
  return (
    <RootDiv>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <StyledPaper>
            <b>Master of Science at the University of Massachusetts, Amherst</b>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPaper>Aug. 2020 - May 2022</StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPaper>
            <i>Master's in Computer Science</i>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPaper>GPA: 3.9/4.0</StyledPaper>
        </Grid>
        <Grid item xs={12} sm={12}>
          <StyledPaper>
            <b>Coursework:</b> Software Engineering, Database Design,
            Distributed & O.S., Neural Networks, Computer Vision, Advanced
            Algorithms, Visual Computing, BI & Analytics, Applied Statistics
          </StyledPaper>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <StyledPaper>
            <b>
              Bachelor of Science at the University of Massachusetts, Amherst
            </b>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPaper>Sep. 2016 - May 2020</StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPaper>
            <i>Double major in Computer Science and Mathematics</i>
          </StyledPaper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledPaper>GPA: 3.8/4.0</StyledPaper>
        </Grid>
        <Grid item xs={12} sm={12}>
          <StyledPaper>
            <b>Coursework:</b> Web Development, Machine Learning, Natural
            Language Processing, Networks, Formal Language Theory, Game
            Programming, Algorithms, Operating Systems
          </StyledPaper>
        </Grid>
      </Grid>
    </RootDiv>
  );
};

export default Education;
